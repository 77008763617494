/* eslint-disable react/prop-types */
import { ToastContainer } from 'react-toastify';
import { IntercomProvider } from 'react-use-intercom';
import { RecoilRoot } from 'recoil';

import I18n from '../lib/i18n';

import 'react-toastify/dist/ReactToastify.css';

export default function MyApp({ Component, pageProps }) {
  return (
    <IntercomProvider appId={process.env.NEXT_PUBLIC_INTERCOM_ID}>
      <I18n lngDict={pageProps.lngDict} locale={pageProps.lng} pageId={pageProps.pageId}>
        <RecoilRoot>
          <Component {...pageProps} />
          <ToastContainer />
        </RecoilRoot>
      </I18n>
    </IntercomProvider>
  );
}
